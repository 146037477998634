export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجعل الطعام عبر الإنترنت والبقالة وتسليم الطرود والتسوق من الصيدليات سريعًا وسهلاً. احصل على توصيل البقالة واطلب الأطعمة المفضلة لديك من أفضل البائعين."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كل ما تحتاجه، يتم تسليمه الآن"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اطلب الطعام عبر الإنترنت، واحصل على خدمة توصيل البقالة، وقم بالتسوق عبر الإنترنت مع أفضل متجر بقالة في منطقتك."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اكتشف البائعين المحليين الذين يقومون بالتوصيل إلى باب منزلك"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" انضم إلى قطار الأعمال السهل"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" انضم إلى قطار الأعمال السهل واكتشف طريقًا أبسط لتحقيق النجاح. انضم الآن واستمتع بتجربة ريادة الأعمال الخالية من المتاعب"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كن بائعًا"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سجل كبائع وافتح متجرًا لبدء عملك اليوم."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يسجل"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خيارات دفع متعددة"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إدارة الطلبات بسهولة"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقديم خيارات دفع متعددة"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التنظيم من البداية إلى النهاية."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كن راكبًا معنا واكسب المزيد"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" انضم إلى فريقنا كراكب واطلق العنان لإمكانياتك في تحقيق الأرباح. استمتع بحرية ساعات العمل المرنة والأجور التنافسية مع توفير السعادة للعملاء."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تصبح متسابق"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اجعل متجرك عبر الإنترنت أسهل من خلال تطبيق الهاتف المحمول الخاص بنا"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قم بتبسيط متجرك عبر الإنترنت بسهولة باستخدام تطبيق الهاتف المحمول الخاص بنا. قم بإدارة أعمالك أثناء التنقل وابق على اتصال مع عملائك في أي وقت وفي أي مكان"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" شركة"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اتصل بنا"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سياسة الخصوصية"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البنود و الظروف"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" التعامل معنا"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تحتاج الدراجين أو السائقين؟"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تريد البيع على"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حساب"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إدارة الحساب"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" طلبات"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خدمات"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ابحث عن متجر"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" فئات"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حقوق النشر"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كل الحقوق محفوظة"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك على موقعنا. من خلال تصفح هذا الموقع، فإنك توافق على استخدامنا لملفات تعريف الارتباط."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يقبل"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تسجيل الدخول"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أفضل مبيعات"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأحدث"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" متميز"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يبحث"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البحث عن المنتجات"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يبحث"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نتائج البحث"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" نتائج البحث عن"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لم يتم العثور على نتائج"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا يوجد نتائج ل"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اظهار الكل"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" شاهد المزيد"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عرض أقل"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البائعين القريبين"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لك"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البائعين المشهورين"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ابحث عن أفضل البائعين في منطقتك"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لدينا جميع البائعين المفضلين لديك هنا"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" القادمون الجدد"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" قادم جديد"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مبيعات فلاش"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" بيع مفاجئ"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كتب"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كتاب"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" مختارات اليوم"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا البائعين"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اختر ملف"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الخدمات الشعبية"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أعلى التقييمات"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" في الأوراق المالية"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إنتهى من المخزن"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأكثر مبيعًا"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حار"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" كمية"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أضف إلى السلة"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" موصى به لك"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يكمل"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" خيارات"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المنتجات من نفس البائع"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" حدد الخيارات لإضافتها إلى المنتج"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عربتك"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سعر"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إجمالي سعر المنتج"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المجموع"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدفع"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عربة التسوق فارغة"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" عربة التسوق فارغة. ابدأ التسوق الآن."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إضافات"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا توجد إضافات متاحة"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ملخص الطلب"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" لا يشمل هذا الإجمالي الفرعي تكلفة التسليم أو الاستلام"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" إجمالي السلعة"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المجموع الفرعي"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" توصيل"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سعر الخصم"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تخفيض"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" المبلغ الإجمالي"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" هل لديك حساب؟"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" استخدم رمز الخصم"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ينضم"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أو"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الدفع"])}
  },
  "login": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" يسجل"])}
  },
  "orders": {
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منتج"])}
  }
}