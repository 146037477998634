<template>
  <div class="top-bar flex items-center justify-between p-4" :style="{ backgroundColor: settings.mainSiteColor }">
    <div class="flex items-center space-x-2 text-white">
      <span v-html="countryFlag"></span>
      <span>{{ locality }}, {{ country }}</span>
      <span class="top-menu-section">|</span>
      <span class="top-menu-section">Tel: {{ phone }}</span>
      <span class="top-menu-section">|</span>
      <span class="top-menu-section">Email: {{ email }}</span>
    </div>
    <div class="social-links flex space-x-2 text-white pr-4">
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.fbLink
            : '#'
        "
        ><img src="/img/icons/facebook.png" class="w-4 h-4 filter-white" alt=""
      /></a>
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.igLink
            : '#'
        "
        ><img src="/img/icons/instagram.png" class="w-4 h-4 filter-white" alt=""
      /></a>
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.twLink
            : '#'
        "
        ><img src="/img/icons/twitter.png" class="w-4 h-4 filter-white" alt=""
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      phone:'',
      email:'',
      settings: {
        mainSiteColor: '#98d1c8', // Example main site color
        strings: {
          website: {
            social: {
              fbLink: '',
              igLink: '',
              twLink: ''
            }
          }
        }
      }
    }
  },
  computed: {
    locality() {
      return this.$store.getters.locality;
    },
    country() {
      return this.$store.getters.country;
    },
    countryFlag() {
      return `<span class="flag-icon flag-icon-${this.country.toLowerCase()}"></span>`;
    }
  },
  mounted() {
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
      if (this.settings.strings.google_maps_key) {
        this.$store.dispatch('fetchLocation', this.settings.strings.google_maps_key); 
      }
      if (this.settings.strings.contactPhone) {
        this.phone = this.settings.strings.contactPhone;
       }
        if (this.settings.strings.contactEmail) {
        this.email = this.settings.strings.contactEmail;
        }
    });
    
  },
  methods: {
    getLocation() {
      if (navigator.geolocation && this.settings && this.settings.strings) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
      } else {
        this.location = 'Geolocation is not supported by this browser.';
      }
    },
    showPosition(position) {
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${this.settings.strings.google_maps_key}`)
        .then(response => response.json())
        .then(data => {
          if (data.results && data.results.length > 0) {
            const addressComponents = data.results[0].address_components;
            for (let component of addressComponents) {
              if (component.types.includes('locality')) {
                this.locality = component.short_name;
              }
              if (component.types.includes('country')) {
                this.country = component.short_name;
              }
            }
          } else {
            this.locality = 'Location not found';
          }
        })
        .catch(error => {
          console.error('Error fetching location:', error);
          this.locality = 'Error fetching location';
        });
    },
    showError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.locality = 'User denied the request for Geolocation.';
          break;
        case error.POSITION_UNAVAILABLE:
          this.locality = 'Location information is unavailable.';
          break;
        case error.TIMEOUT:
          this.locality = 'The request to get user location timed out.';
          break;
        case error.UNKNOWN_ERROR:
          this.locality = 'An unknown error occurred.';
          break;
      }
    }
  }
}
</script>

<style scoped>
@media (max-width: 640px) {
  .top-menu-section {
    display:none;
  }
}
.filter-white {
  filter: invert(1) grayscale(1) brightness(2);
}
.top-bar {
  padding: 10px;
}
.text-white {
  color: white;
}
.social-links {
  margin-left: auto;
}
.social-links a {
  text-decoration: none;
  color: white;
  margin-right:4px;
}
.social-links a:hover {
  text-decoration: underline;
}
</style>
