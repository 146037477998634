// store/modules/location.js
const state = {
  locality: '',
  country: 'KE',
  error: ''
};

const mutations = {
  setLocality(state, locality) {
    state.locality = locality;
  },
  setCountry(state, country) {
    state.country = country;
  },
  setError(state, error) {
    state.error = error;
  }
};

const actions = {
  fetchLocation({ commit }, googleMapsKey) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleMapsKey}`)
            .then(response => response.json())
            .then(data => {
              if (data.results && data.results.length > 0) {
                const addressComponents = data.results[0].address_components;
                for (let component of addressComponents) {
                  if (component.types.includes('locality')) {
                    commit('setLocality', component.short_name);
                  }
                  if (component.types.includes('country')) {
                    commit('setCountry', component.short_name);
                  }
                }
              } else {
                commit('setError', 'Location not found');
              }
            })
            .catch(error => {
              commit('setError', 'Error fetching location');
              console.error('Error fetching location:', error);
            });
        },
        (error) => {
          switch(error.code) {
            case error.PERMISSION_DENIED:
              commit('setError', 'User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              commit('setError', 'Location information is unavailable.');
              break;
            case error.TIMEOUT:
              commit('setError', 'The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              commit('setError', 'An unknown error occurred.');
              break;
          }
        }
      );
    } else {
      commit('setError', 'Geolocation is not supported by this browser.');
    }
  }
};

const getters = {
  locality: state => state.locality,
  country: state => state.country,
  locationError: state => state.error
};

export default {
  state,
  mutations,
  actions,
  getters
};
