<template>
  <div class="w-full p-8" v-if="settings" :style="{ backgroundColor: settings.mainSiteColor }">
    <div class="grid md:gap-5 md:grid-cols-4">
      <div class="flex flex-col mt-3 md:mt-0 md:items-center md:w-100">
        <div class="flex flex-shrink-0">
          <a href="/" class="flex items-center">
            <img :src="settings.strings.website.websiteFooterImage" class="mr-3" alt="Flowbite Logo" />
          </a>
        </div>
        <p class="my-4 text-sm font-light text-white justify-mobile">
          {{settings.strings.website.websiteHeaderSubtitle}}
        </p>
        <!-- <button
          class="h-12 px-4 py-2 font-light text-white bg-blue-800 rounded-md w-44"
        >
          Get in touch
        </button> -->
      </div>
      <div class="flex flex-col mt-3 md:items-center md:w-52 md:mt-0">
        <div class="flex flex-col">
          <h1 class="mb-3 text-sm font-medium text-white uppercase">{{ $t('footer.header1') }}</h1>
          <a
            class="mb-3 text-sm font-light text-white"
            target="__blank"
            :href="`${this.$store.state.baseUrl}pages/contact`"
            >{{ $t('footer.sub1') }}</a
          >
          <a
            class="mb-3 text-sm font-light text-white"
            target="__blank"
            :href="`${this.$store.state.baseUrl}privacy/policy`"
            >{{ $t('footer.sub2') }}</a
          >
          <a
            class="mb-3 text-sm font-light text-white"
            target="__blank"
            :href="`${this.$store.state.baseUrl}pages/terms`"
            >{{ $t('footer.sub3') }}</a
          >
        </div>
      </div>
      <div class="flex flex-col mt-3 md:mt-0 md:items-center md:w-52">
        <div class="flex flex-col">
          <h1 class="mb-3 text-sm font-medium text-white uppercase">
            {{ $t('footer.header2') }}
          </h1>
          <a
            class="mb-3 text-sm font-light text-white"
            target="__blank"
            :href="`${this.$store.state.baseUrl}register/driver`"
            >{{ $t('footer.sub4') }}</a
          >
          <a
            class="mb-6 text-sm font-light text-white"
            target="__blank"
            :href="`${this.$store.state.baseUrl}register/vendor`"
            >{{ $t('footer.sub5') }} {{ settings.strings.app_name }}?</a
          >
        </div>
      </div>
      <div class="flex flex-col mt-3 md:mt-0 md:items-center md:w-52">
        <div class="flex flex-col">
          <h1 class="mb-3 text-sm font-medium text-white uppercase">{{ $t('footer.header3') }}</h1>
          <a href="" class="mb-3 text-sm font-light text-white"
            >{{ $t('footer.sub6') }}</a
          >
          <a href="" class="mb-3 text-sm font-light text-white">{{ $t('footer.sub7') }}</a>
        </div>
      </div>
      <!-- <div class="flex flex-col mt-3 md:mt-0 md:items-center md:w-52">
        <div class="flex flex-col">
          <h1 class="mb-3 text-sm font-medium text-white uppercase">{{ $t('footer.header5') }}</h1>
          <a
            class="mb-3 text-sm font-light text-white"
            v-for="vendor in vendors"
            :key="vendor.id"
          >
            <a
              class="text-white"
              :href="
                $router.resolve({
                  params: {
                    id: vendor.id,
                    slug: sanitizeTitle(`${vendor.slug}`),
                  },
                }).href
              "
              >{{ vendor.name }}</a
            >
          </a>
        </div>
      </div> -->
    </div>
    <p class="mt-10 text-sm font-light text-white text-center justify-mobile">
      {{ settings.strings.website.websiteFooterBrief }}
    </p>
    <div class="flex justify-end space-x-2">
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.fbLink
            : '#'
        "
        ><img src="/img/icons/facebook.png" class="w-4 h-4 filter-white" alt=""
      /></a>
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.igLink
            : '#'
        "
        ><img src="/img/icons/instagram.png" class="w-4 h-4 filter-white" alt=""
      /></a>
      <a
        :href="
          settings.strings.website.social
            ? settings.strings.website.social.twLink
            : '#'
        "
        ><img src="/img/icons/twitter.png" class="w-4 h-4 filter-white" alt=""
      /></a>
    </div>
    <!-- <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"> -->
  </div>
  <footer class="w-full py-4 text-center text-white" :style="{ backgroundColor: darkenColor(settings.mainSiteColor, 0.2) }">
      Copyright &copy; {{ currentYear }} {{settings.strings.app_name}}. All rights reserved.
  </footer>
</template>
<style scoped>
.filter-white {
  filter: invert(1) grayscale(1) brightness(2);
}

@media (max-width: 640px) {
  .center-mobile {
    text-align: center; 
  }

  .justify-mobile {
    text-align: justify;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "FooterView",
  data() {
    return {
      settings: null,
      vendors: null,
      base_url: this.$store.state.baseUrl,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
    });
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    const headers = {
      lang: selectedLanguage,
    };
    axios
      .get(this.base_url + "api/vendor/types", {headers})
      .then((response) => {
        let vendorTypes = response.data;
        this.vendors = vendorTypes.slice(0, 4);
      })
      .catch((error) => console.log(error));
  },

  methods: {
    darkenColor(color, percent) {
      const f = parseInt(color.slice(1), 16);
      const t = percent < 0 ? 0 : 255;
      const p = percent < 0 ? percent * -1 : percent;
      const R = f >> 16;
      const G = (f >> 8) & 0x00FF;
      const B = f & 0x0000FF;
      return `#${(
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)}`;
    },
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
